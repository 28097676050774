<template>
  <b-card-code
    title="PV de Délibération Session Principaleee"
    no-body
  >
    <b-table-simple
      hover
      caption-top
      responsive
      class="rounded-bottom mb-0"
    >
      <b-tbody head-variant="light">
        <b-tr>
          <b-th
            rowspan="4"
            colspan="3"
          />
          <b-th class="text-right">
            Unité
          </b-th>
          <b-td
            colspan="4"
            variant="danger"
          />
          <b-td
            colspan="4"
            variant="danger"
          >UE F11</b-td>
        </b-tr>
        <b-tr>
          <b-th class="text-right">
            Matiére
          </b-th>
          <b-td
            colspan="4"
            variant="secondary"
          >
            Droit constitutionnel 1
          </b-td>
          <b-td
            colspan="4"
            variant="danger"
          >
            Droit objectif
          </b-td>
        </b-tr>
        <b-tr>
          <b-th class="text-right">
            Coefficient
          </b-th>

          <b-td colspan="4">
            3
          </b-td>
          <b-td
            colspan="4"
            variant="danger"
          >
            3
          </b-td>
        </b-tr>
        <b-tr>
          <b-th
            class="text-right"
          >
            Crédit
          </b-th>
          <b-td colspan="4">
            6
          </b-td>
          <b-td
            colspan="4"
            variant="danger"
          >6</b-td>
        </b-tr>
      </b-tbody>
      <b-tbody>
        <b-tr>
          <b-th>N°</b-th>
          <b-th>Nom</b-th>
          <b-th>Prénom</b-th>
          <b-th>CIN</b-th>
          <b-th colspan="1">
            CC
          </b-th>
          <b-th colspan="1">
            DS
          </b-th>
          <b-th>EX</b-th>
          <b-th>MY</b-th>
          <b-th colspan="1">
            CC
          </b-th>
          <b-th colspan="1">
            DS
          </b-th>
          <b-th>EX</b-th>
          <b-th>MY</b-th>
        </b-tr>
        <b-tr
          v-for="item in students"
          :key="item.id"
        >
          <b-th>
            {{ item.id }}
          </b-th>
          <b-th
            class="text-right"
          >
            {{ item.username }}
          </b-th>
          <b-th class="text-right">
            {{ item.email }}

          </b-th>
          <b-td>{{ item.cin }}</b-td>
          <b-td
            v-for="childItem in notes"
            :key="childItem.id"
          >
            {{ childItem.cc }}
          </b-td>
          <b-td>
            0
          </b-td>
          <b-td> 0</b-td>

          <b-td variant="success">
            12
          </b-td>
        </b-tr>
        <b-tr>
          <b-th>
            2
          </b-th>
          <b-th class="text-right">
            Syrine
          </b-th>
          <b-th class="text-right">
            Karwi
          </b-th>
          <b-td variant="success">
            0988774455
          </b-td>
          <b-td>17.00</b-td>
          <b-td>14.75</b-td>
          <b-td>13.00</b-td>
          <b-td>13.70</b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-td
            colspan="7"
            variant="secondary"
            class="text-right"
          >
            Total Rows: <b>5</b>
          </b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>

    <template #code>
      <!-- {{ codeSimple }} -->
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTableSimple, BTr, BTh, BTd, BTbody, BTfoot,
} from 'bootstrap-vue'
import axios from 'axios'

// import { codeSimple } from './code'

export default {
  components: {
    BCardCode,
    BTableSimple,
    // BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
  },
  data() {
    return {
      notes: [],
      subjects: [],
      students: [],
    //   codeSimple,
    }
  },
  created() {
    this.getNotesSesmester2()
    this.getSubjectGroup()
    this.getStudentGroup()
  },
  methods: {
    async getNotesSesmester2() {
      this.load = 'true'
      const response = await axios.get('/api/notes/get-deliberation/')
      this.notes = response.data
      this.load = 'false'
    },
    async getSubjectGroup() {
      this.load = 'true'
      const response = await axios.get('/api/notes/get-subjects/')
      this.subjects = response.data
      this.load = 'false'
    },
    async getStudentGroup() {
      this.load = 'true'
      const response = await axios.get('/api/notes/get-students/')
      this.students = response.data
      this.load = 'false'
    },
  },

}
</script>
